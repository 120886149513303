.onboarding-blue-lining {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 400px;

  background: #337bf7;
}

.onboarding-nav {
  position: relative;
  z-index: 9;
}

.onboarding-nav .loader-wrapper {
  display: inline-block;
  padding: 5px 15px;
  height: 50px;
}

.onboarding-nav .loader-wrapper img {
  height: 100%;
}

.onboarding .content {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.75);
}

.onboarding-wrapper {
  min-height: 400px;
}

.onboarding-steps {
  margin-bottom: 40px;
}

.onboarding-header {
  text-align: center;
  margin-bottom: 30px;
}
.onboarding-header h1 {
  font-size: 28px;
  line-height: 32px;
}

.onboarding-header_description {
  font-size: 20px;
  line-height: 32px;
}

.onboarding-content {
  min-height: 200px;
}

.onboarding-link {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

.onboarding-sign-in {
  border-color: #1890ff;
}

.onboarding-content .message-preview {
  background: #3ea2f8;
  padding: 10px 15px;
  border-radius: 6px;
  color: #fff;
  line-height: 20px;
  min-height: 40px;
}

.onboarding-content .ant-form-item-label {
  line-height: 20px;
}
