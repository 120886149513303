@import "~antd/dist/antd.css";
@import "./variables.css";

.spinner-wrapper {
  text-align: center;
  padding: 20px 0;
}

.ant-row.ant-form-item {
  margin-top: 0;
  margin-bottom: 10px;
}

.ant-form .ant-form-item-label {
  line-height: 30px;
  font-weight: 500;
}

.mb-15 {
  margin-bottom: 15px;
}

.text-center {
  text-align: center;
}

.full-spin-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-v {
  display: flex;
  align-items: center;
}

.time-line-value {
  line-height: 18px;
  margin-bottom: 8px;
}

.time-line-value p {
  margin-bottom: 5px;
}
